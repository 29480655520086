import { InjectionKey } from "vue";
import { CookieOptionsValues, SuccessModalTypes } from "~/types/entities";

export const successModalKey = Symbol("") as InjectionKey<
  (type: SuccessModalTypes) => void
>;

export const cookieValueKey = Symbol("") as InjectionKey<
  Ref<CookieOptionsValues>
>;
